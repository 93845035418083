import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const SEO = ({
    title,
    slug,
    description,
    titleMedia,
    imageFb
}) => {
    const data = useStaticQuery(graphql`
    query {
        site {
            siteMetadata {
                title
                author
                siteUrl
            }
        }
        datoCmsAsset(filename: {eq: "seoimage.png"}) {
            url
          }
    }`)

    const info = {
        description: 'Responsywne strony internetowe - indywidualny projekt i realizacja. Projektowanie graficzne materiałów reklamowych, UI i UX design, aplikacje desktopowe oraz webowe.',
        keyWords: ['responsywne strony internetowe', 'strony internetowe', 'strony www', 'aplikacje webowe', 'UI', 'UX', 'CMS', 'strony z zarządzaniem treścią', 'nowoczesne strony internetowe'],
        titleMedia: 'IKS design - Strony internetowe, projektowanie graficzne - Gliwice Śląskie',
        imageFb: data.datoCmsAsset.url,
        twitterCreator: '', //twitterID
        twitterUrl: '',
    }

    return (
        <Helmet>
            <html lang="pl" />
            <meta charset="utf-8" />
            <title>{`${title} | ${data.site.siteMetadata.title}`}</title>
            <meta
                name="description"
                content={description || info.description} />
            <meta
                name="keywords"
                content={info.keyWords.join(', ')} />
            <meta
                name="author"
                content={data.site.siteMetadata.author} />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1" />

            {/*GOOGLE*/}
            <meta
                name="google-site-verification"
                content="z3K3c4pDkksRFAKddk_SAUkazIyJpXwCyxYnytyugT8" />

            {/*FACEBOOK*/}
            <meta
                property="og:url"
                content={`${data.site.siteMetadata.siteUrl}${slug || ''}`} />
            <meta
                property="og:type"
                content="website" />
            <meta
                property="og:title"
                content={titleMedia || info.title} />
            <meta
                property="og:description"
                content={description || info.description} />
            <meta
                property="og:image"
                content={imageFb || info.imageFb} />

            <meta
                property="og:locale"
                content='pl_PL' />

            {/*TWITTER*/}
            <meta
                name="twitter:card"
                content="summary_large_image" />
            <meta
                name="twitter:creator"
                content={info.twitterCreator} />
            <meta
                name="twitter:site"
                content={info.twitterUrl} />
            <meta
                name="twitter:title"
                content={titleMedia || info.titleMedia} />
            <meta
                name="twitter:description"
                content={description || info.description} />
            <meta
                name="twitter:image"
                content={imageFb || info.imageFb} />
        </Helmet>
    )
}
export default SEO